import { Response } from 'miragejs';

export const exposuresRequestRouter = (
  claimNumber: string,
  exposureNumber: string
) => {
  if (claimNumber === '123456789' && exposureNumber === '0000') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: null
        },
        errors: [
          {
            path: ['findExposures'],
            data: null,
            errorType: '1100',
            errorInfo: null,
            locations: [
              {
                line: 2,
                column: 3,
                sourceName: null
              }
            ],
            message: 'Authorization Error'
          }
        ]
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0001') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: null
        },
        errors: [
          {
            path: ['findExposures'],
            data: null,
            errorType: '3000',
            errorInfo: null,
            locations: [
              {
                line: 2,
                column: 3,
                sourceName: null
              }
            ],
            message: 'findExposures Service error'
          }
        ]
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0002') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Open',
              // lossParty: 'Insured', Swapped for demo site
              lossParty: 'Third Party',
              okToPay: 'Yes',
              claimNumber: '123456789',
              rentalCoverage: 'Yes',
              coverageSummary: {
                type: 'Collision',
                claimDeductible: {
                  amount: '53.00',
                  waived: 'Waived',
                  remainingAmount: '100.00'
                }
              },
              incident: {
                id: '12346',
                name: '2019 Mazda CX-3',
                type: 'Multiple vehicle crash and rollover',
                dateTime: '01/13/2022 at 03:34 pm',
                location: '34 Stone Street, Mytown, ST 02134',
                cause: 'Object in road'
              },
              claimant: {
                name: 'Steven Goncalves',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '123-123-1234',
                      extension: '6669',
                      type: 'Home',
                      isPrimary: true
                    },
                    {
                      phoneNumber: '123-123-1235',
                      extension: '6669',
                      type: 'Home',
                      isPrimary: null
                    }
                  ],
                  emailList: ['steven@noemail.com']
                },
                address: {
                  addressLine1: '75 MCLAUGHLIN RD',
                  addressLine2: null,
                  city: 'BROCKPORT',
                  state: 'PA',
                  postalCode: '15823-1801',
                  country: 'United States'
                }
              },
              owner: {
                name: 'Sam Dover',
                communication: {
                  emailList: ['samd@libertymutual.com'],
                  phoneList: [
                    {
                      phoneNumber: '1-800-332-3226'
                    },
                    {
                      phoneNumber: '603-334-8232'
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0003') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Open',
              lossParty: 'Third Party',
              okToPay: 'No',
              claimNumber: '123456789',
              rentalCoverage: 'Yes',
              coverageSummary: {
                type: 'Liability Property Damage',
                claimDeductible: {
                  amount: '350.00',
                  waived: null,
                  remainingAmount: '2000.00'
                }
              },
              incident: {
                id: '12345',
                name: '2019 Mazda CX-5',
                type: null,
                dateTime: null,
                location: null,
                cause: null
              },
              claimant: {
                name: 'Kaylee Goncalves',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '111-222-3333',
                      extension: '',
                      type: 'Home',
                      isPrimary: true
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0004') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Open',
              lossParty: 'Third Party',
              okToPay: 'NA',
              claimNumber: '123456789',
              coverageSummary: {
                type: 'Liability Property Damage',
                claimDeductible: {
                  amount: '150.00',
                  waived: null,
                  remainingAmount: '200.00'
                }
              },
              incident: {
                id: 'xxxxx', //Will throw error in repair-status router,
                name: '2019 Mazda CX-9',
                type: 'Multiple vehicle crash and rollover',
                dateTime: '01/13/2022 at 03:34 pm',
                location: '34 Stone Street, Mytown, ST 02134',
                cause: 'Object in road'
              },
              claimant: {
                name: 'Aileen Cannon',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '123-123-1234',
                      extension: '',
                      type: 'Home',
                      isPrimary: true
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0005') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Open',
              lossParty: 'Third Party',
              okToPay: 'yes',
              claimNumber: '123456789',
              coverageSummary: {
                type: 'Liability Property Damage',
                claimDeductible: {
                  amount: '150.00',
                  waived: null,
                  remainingAmount: '200.00'
                }
              },
              incident: {
                id: '12347',
                name: '2016 Honda S2000',
                type: 'Multiple vehicle crash and rollover',
                dateTime: '01/13/2022 at 03:34 pm',
                location: '34 Stone Street, Mytown, ST 02134',
                cause: 'Object in road'
              },
              claimant: {
                name: 'XX YY',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '123-123-1234',
                      extension: '',
                      type: 'Home',
                      isPrimary: true
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0006') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Open',
              lossParty: 'Third Party',
              okToPay: 'no',
              claimNumber: '123456789',
              coverageSummary: {
                type: 'Liability Property Damage',
                claimDeductible: {
                  amount: '150.00',
                  waived: null,
                  remainingAmount: '200.00'
                }
              },
              incident: {
                id: '12345',
                name: '2019 Mazda CX-5',
                type: 'Multiple vehicle crash and rollover',
                dateTime: '01/13/2022 at 03:34 pm',
                location: '34 Stone Street, Mytown, ST 02134',
                cause: 'Object in road'
              },
              claimant: {
                name: 'XX YY',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '123-123-1234',
                      extension: '',
                      type: 'Home',
                      isPrimary: true
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0007') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Open',
              lossParty: 'Third Party',
              okToPay: 'yes',
              claimNumber: '056694445',
              coverageSummary: {
                type: 'Liability Property Damage',
                claimDeductible: {
                  amount: null,
                  remainingAmount: null,
                  waived: null,
                  __typename: 'claimDeductible'
                },
                __typename: 'CoverageSummary'
              },
              incident: {
                id: 'cc:1155801',
                __typename: 'Incident'
              },
              __typename: 'Exposure'
            }
          ]
        }
      }
    );
  } else if (claimNumber === '056359703' && exposureNumber === '0002') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Closed',
              lossParty: 'Insured',
              okToPay: 'yes',
              claimNumber: '056359703',
              coverageSummary: {
                type: 'Collision',
                claimDeductible: {
                  amount: '150.00',
                  waived: null,
                  remainingAmount: '200.00'
                }
              },
              incident: {
                id: '12345',
                name: '2022 Mazda MX-5',
                type: 'Multiple vehicle crash and rollover',
                dateTime: '01/13/2022 at 03:34 pm',
                location: '34 Stone Street, Mytown, ST 02134',
                cause: 'Object in road'
              },
              claimant: {
                name: 'Mike Leach',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '999-999-9999',
                      extension: '',
                      type: 'Home',
                      isPrimary: true
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '056359703' && exposureNumber === '0003') {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: [
            {
              status: 'Closed',
              lossParty: 'Third Party',
              okToPay: 'yes',
              claimNumber: '123456789',
              coverageSummary: {
                type: 'Liability Property Damage',
                claimDeductible: {
                  amount: '150.00',
                  waived: null,
                  remainingAmount: '200.00'
                }
              },
              incident: {
                id: '12345',
                name: '2018 Toyota RAV 4',
                type: 'Multiple vehicle crash and rollover',
                dateTime: '01/13/2022 at 03:34 pm',
                location: '34 Stone Street, Mytown, ST 02134',
                cause: 'Object in road'
              },
              claimant: {
                name: 'Karen Bass',
                communication: {
                  phoneList: [
                    {
                      phoneNumber: '888-888-8888',
                      extension: '',
                      type: 'Home',
                      isPrimary: true
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    );
  } else {
    return new Response(
      200,
      {},
      {
        data: {
          findExposures: []
        }
      }
    );
  }
};

export default exposuresRequestRouter;

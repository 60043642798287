import { observer } from 'mobx-react';
import { GridRow, GridCol, Heading, Form, FieldGroup, AlphanumericInput, Button } from '@lmig/lmds-react';
import styles from '../claim-search.module.scss';
import { claimSearchStore as store } from '../store/claim-search-store';
import PageError from '../../common/components/page-error/page-error';
import { appraisalStore } from '../../appraisal/store/appraisal-store';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { tealiumEvent } from '../../../utils/tealium';

const ClaimSearchForm = observer(() => {
  const renderFormHeader = (): JSX.Element => (
    <GridRow gutters>
      <GridCol base={12}>
        {store.error && <PageError>{store.error}</PageError>}
        <Heading type={'h2-light'}>What claim number are you looking for?</Heading>
      </GridCol>
    </GridRow>
  );

  const renderFormBody = (): JSX.Element => ( 
    <GridRow gutters>
      <GridCol base={12} md={4}>
        <FieldGroup
          id={'registration-user-info'}
          labelStyle={'h2-light'}
          messages={!store.formSubmittable && store.blur ? [{type: 'error', text: 'Invalid claim number'}] : []}
          className={styles.fieldGroup}
        >				
          <AlphanumericInput
            labelVisual="Enter claim number. (eg. 123456789-0002)"
            data-testid="claim-number-input"
            onBlur={() => store.blur = true}
            onFocus={() => store.blur = false}
            onChange={(e: any) =>
              store.onChange(e.target.value.trim().replace(/(\d{9})-?(\d{4})/, '$1-$2'))
            }
            highlightType={!store.formSubmittable && store.blur ? 'error' : undefined}
            value={store.claimNumber}
          />			
        </FieldGroup>
      </GridCol>
    </GridRow>
  );

  const renderSearchButton = (): JSX.Element => (
    <GridRow gutters>
      <GridCol base={12} md={4}>
        <Button
          data-name="cru-render-claim-search-btn"
          data-testid="claim-search-button"
          size={'large'}
          variant={'primary'}
          dynamicWidth
          disabled={store.formSubmittable ? false : true}
          type={'submit'}
          onClick={handleClaimSearch}>
				Search
        </Button>
      </GridCol>
    </GridRow>
  );

  const handleClaimSearch = () => {
    trackCustomHeapEvent('claim-search-button-clicked', { claimNumber: store.claimNumber });
    tealiumEvent('CIP-Claim-Search-Info', { claimNumber: store.claimNumber });
    store.onSubmit();
    appraisalStore.resetAppraisal();
  };
  
  return ( 
    <Form>
      {renderFormHeader()}
      {renderFormBody()}
      {renderSearchButton()}
    </Form>
  );
});

export default ClaimSearchForm;

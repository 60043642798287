export const LiabilityInvestigationStatus = {
  COMPLETE: 'Complete',
  IN_PROGRESS: 'In Progress'
};

export const LiabilityDecision = {
  ACCEPTED: 'Liability Accepted',
  DENIAL: 'Liability Denial',
  PARTIAL_DENIAL: 'Partial Liability Denial',
  PENDING: 'Investigation Pending'
};

export const LiabilityStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED_ACCEPTED: 'COMPLETED_ACCEPTED',
  PARTIAL_LIABILITY_DENIAL: 'PARTIAL_LIABILITY_DENIAL',
  COMPLETED_NOT_ACCEPTED: 'COMPLETED_NOT_ACCEPTED'
};

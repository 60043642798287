import { ILiabilityInvestigation } from '../features/claim-search/models/claim-interface';
import { LiabilityDecision, LiabilityInvestigationStatus, LiabilityStatus } from './liability-response';

export const getLiabilityInvestigationStatus = (liabilityInvestigationResponse: ILiabilityInvestigation | null): string => {
  // if no data (not started) return not started
  if (liabilityInvestigationResponse === null || Object.keys(liabilityInvestigationResponse).length === 0) {
    return LiabilityStatus.NOT_STARTED; 
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus === LiabilityInvestigationStatus.IN_PROGRESS) {
    return LiabilityStatus.IN_PROGRESS;
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus === LiabilityInvestigationStatus.COMPLETE && 
    liabilityInvestigationResponse.liabilityDecision === LiabilityDecision.ACCEPTED &&
    liabilityInvestigationResponse.liabilityHighPercent === 100) {
    return LiabilityStatus.COMPLETED_ACCEPTED;
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus === LiabilityInvestigationStatus.COMPLETE && 
    liabilityInvestigationResponse.liabilityDecision === LiabilityDecision.PARTIAL_DENIAL &&
    liabilityInvestigationResponse.liabilityHighPercent !== 100) {
    return LiabilityStatus.PARTIAL_LIABILITY_DENIAL;
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus === LiabilityInvestigationStatus.COMPLETE && 
    liabilityInvestigationResponse.liabilityDecision !== LiabilityDecision.ACCEPTED) {
    return LiabilityStatus.COMPLETED_NOT_ACCEPTED;
  }

  // if any other case like not accepted should it be Not started
  return LiabilityStatus.NOT_STARTED;
};

import { observer } from 'mobx-react';
import {
  Card, GridCol, Heading, GridRow, BodyText, Badge,
  IconStopwatch, IconInfo, IconCloseCircle } from '@lmig/lmds-react';
import { TableBody, Table, TableCell, TableRow } from '@lmig/lmds-react-table';
import Divider from './divider-component';
import { IExposureInfo, ILiabilityInvestigation } from '../models/claim-interface';
import { convertToShortDate } from '../../../utils/common';
import { getPrimaryPhoneNumber } from '../../../utils/get-phone-number';
import { getLiabilityInvestigationStatus } from '../../../utils/get-liability-status';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import { LiabilityStatus } from '../../../utils/liability-response';
import styles from './claim-information-component.module.scss';

interface ClaimInformationPropsType {
  claimNumber: string;
  exposureInfo: IExposureInfo | null;
  liabilityInvestigation: ILiabilityInvestigation | null;
  rentalCoverage: string;
  okayToPay: string;
  isLoading: boolean;
  liabilityInvestigationError: string;
}

interface StatusPropsType {
  color: 'informative' | 'negative' | 'positive';
}

const Space = (props: { height: number }): JSX.Element => (
  <div style={{
    flexBasis: '100%',
    height: props.height
  }} />
);

const Status = (props: React.PropsWithChildren<StatusPropsType>): JSX.Element => {
  let className = styles.iconWrapper + ' ' + styles[props.color];
  return (
    <p>
      <div className={className} >
        <IconInfo size="16" color={props.color} />
      </div>
      { props.children }
    </p>
  );
};

const renderLiabilityStatus = (status: string, date: string | null) => {
  const _date = date ? date : '-';
  if (status === LiabilityStatus.COMPLETED_ACCEPTED) {
    return (
      <Status color="positive">
        Liberty Mutual has accepted 100% liability as of {convertToShortDate(_date)}
      </Status>
    );
  } else if (status === LiabilityStatus.IN_PROGRESS || status === LiabilityStatus.NOT_STARTED) {
    // if the status is not started we show date of today
    return (
      <Status color="informative">
        Liberty Mutual is currently determining liability as of {convertToShortDate(status === 'NOT_STARTED' ? new Date().toJSON() : _date)}
      </Status>
    );
  } else if (status === LiabilityStatus.COMPLETED_NOT_ACCEPTED) {
    return (
      <Status color="negative">
        Liberty Mutual has declined liability as of {convertToShortDate(_date)}
      </Status>
    );
  } else if (status === LiabilityStatus.PARTIAL_LIABILITY_DENIAL) {
    return (
      <Status color="negative">
        Please verify liability status with claims adjuster
      </Status>
    );
  }
};

const renderOkayToPay = (status: string) => {
  if (status === 'yes') {
    return (
      <Status color="positive">
        Yes - Liberty Mutual has approved payment
      </Status>
    );
  } else if (status === 'no') {
    return (
      <Status color="negative">
        No - Liberty Mutual has rejected payment
      </Status>
    );
  } else {
    return ( 
      <Status color='informative'>
        Pending - Liberty Mutual is making a determination
      </Status>
    );
  }
};

const ClaimInformation = observer((props: ClaimInformationPropsType) => {
  return (
    props.isLoading ? <CardShimmer></CardShimmer> :
      <GridCol base={12}>
        <Card
          elevation="raised"
        >
          <Heading type="h3-bold">
          Claim information
          </Heading>
          <GridRow gutters>
            <GridCol sm={12} md={5}>
              <Heading type="h4-light">
              Claim #: { props.claimNumber }
              </Heading>
              <Table className={styles.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={styles.tableCellLeft}>
                      <BodyText type="default-medium">
                      Vehicle
                      </BodyText>
                    </TableCell>
                    <TableCell className={styles.cellPadding} textAlign='right'>
                      { props.exposureInfo ? props.exposureInfo?.incident.name : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCellLeft}>
                      <BodyText type="default-medium">
                      Claim contact
                      </BodyText> 
                    </TableCell>
                    <TableCell textAlign='right'>
                      { props.exposureInfo ? props.exposureInfo?.claimant?.name : 'N/A' }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCellLeft}>
                      <BodyText type="default-medium">
                    Phone number
                      </BodyText> 
                    </TableCell>
                    <TableCell textAlign='right'>
                      { props.exposureInfo ? getPrimaryPhoneNumber(props.exposureInfo?.claimant?.communication?.phoneList) : 'N/A' }
                    </TableCell>
                  </TableRow>
                  <Space height={40} />
                  <TableRow>
                    <TableCell className={styles.tableCellLeft}>
                      <BodyText type="default-medium">
                      Type of coverage
                      </BodyText> 
                    </TableCell>
                    <TableCell textAlign='right'>
                      { props.exposureInfo ? props.exposureInfo?.coverageSummary.type : 'N/A' }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCellLeft}>
                      <BodyText type="default-medium">
                      Rental coverage
                      </BodyText> 
                    </TableCell>
                    <TableCell textAlign='right' data-testid='claimInformation_rentalCoverage'>
                      { props.rentalCoverage }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </GridCol>
            <Divider md={2}/>
            <GridCol sm={12} md={5}>
              <GridRow gutters>
                <Heading type="h4-bold">
                Claim status
                </Heading>
                <Space height={0} />
                { props.exposureInfo && 
                <Badge
                  highlightType={props.exposureInfo?.status === 'Open' ? 'positive': undefined}
                  className={props.exposureInfo?.status === 'Open' ? undefined : styles.closeIcon}
                  icon={props.exposureInfo?.status === 'Open' ? <IconStopwatch color="positive" size="16" /> : <IconCloseCircle color="gray" size="16" />}
                >
                  { props.exposureInfo?.status }
                </Badge>
                }
              </GridRow>
              {(props.exposureInfo && props.exposureInfo?.lossParty === 'Third Party') &&
              <GridRow gutters>
                <Heading type="h4-bold">
                  Liability status
                </Heading>
                <Space height={0} />
                {props.liabilityInvestigationError ?
                  props.liabilityInvestigationError :
                  renderLiabilityStatus(
                    getLiabilityInvestigationStatus(props.liabilityInvestigation),
                    props.liabilityInvestigation ? props.liabilityInvestigation.liabilityUpdateTime : null
                  )
                }
              </GridRow>
              }
              <GridRow gutters id='ok-to-pay'>
                <Heading type="h4-bold">
                Okay to pay
                </Heading>
                <Space height={0} />
                <span data-testid='claimInformation_okToPay'>
                  {renderOkayToPay(props.okayToPay.toLowerCase())}
                </span>
              </GridRow>
            </GridCol>
          </GridRow>
        </Card>
      </GridCol>
  );
});

export default ClaimInformation;


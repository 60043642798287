import analyticsManager from '@lmig/crab-rangoons';
import env from './env';
import { Envs } from '../features/common/models/generic-enum';
import routesConfig from '../routes-config';
import { authService } from '../features/common/services';

const tealiumPageLoad = (pageSubName: string) => {

  const payload = {
    pageName: env.pageName,
    pageSubName,
    recipientId: authService.getUserRecipientId()
  };

  analyticsManager.dispatchPageload(payload);
};

const tealiumEvent = (eventName: string, eventProps?: any) => {

  const page = Object.values(routesConfig).find((route: any) =>
    window.location.pathname.match(route.regexPath)
  );
  
  const payload = {
    pageName: env.pageName,
    pageSubName: page?.subPageName,
    eventName,
    recipientId: authService.getUserRecipientId(),
    ...eventProps
  };

  analyticsManager.dispatch(payload);

  if (env.appEnv===Envs.Local){
    console.log('custom tealium event > ', eventName, eventProps);
  }
};

export { tealiumPageLoad, tealiumEvent };
